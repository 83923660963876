import { BiblioteksentralenLogo, colors } from "@biblioteksentralen/js-utils";
import { Box, Heading, Link, Stack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import { DefaultContainer } from "../ContentContainer";
import { ResolvedSite } from "../site/sanityQuery";
import SitePreview from "./SitePreview";

interface Props {
  sites: ResolvedSite[];
}

function Librycontent(props: Props) {
  return (
    <Box>
      <Box padding="1.5rem" background={colors.black} color="white">
        <Link href="https://www.bibsent.no/">
          <Stack direction="row" spacing="1rem" alignItems="center" fontWeight="600">
            <BiblioteksentralenLogo fontSize="1.5rem" /> <span>Biblioteksentralen</span>
          </Stack>
        </Link>
      </Box>
      <DefaultContainer>
        <VStack padding="calc(10vw + 5rem) 0" spacing="4rem" minH="100vh">
          <Stack direction="column" spacing="1rem">
            <Heading as="h1" textTransform="uppercase" size="4xl" lineHeight=".8" fontWeight="normal">
              <Box fontSize=".25em" marginLeft=".25em">
                Libry
              </Box>
              <Box>Content</Box>
            </Heading>
            <Text>En nettsidebygger for bibliotekene i Norge</Text>
          </Stack>
          <Stack direction="column" spacing="1.5rem">
            {props.sites.map((site) => (
              <SitePreview site={site} key={site._id} />
            ))}
          </Stack>
        </VStack>
      </DefaultContainer>
    </Box>
  );
}

export default Librycontent;
