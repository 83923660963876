import { Library } from "@libry-content/types";
import { createContext, ReactNode, useContext } from "react";
import { ResolvedSite } from "../site/sanityQuery";

export interface SiteContext {
  site?: ResolvedSite;
  library?: Library;
}

const Context = createContext<SiteContext>({});

export const useSiteContext = () => useContext(Context);

interface Props {
  context: SiteContext;
  children: ReactNode;
}

export const SiteContextProvider = (props: Props) => {
  return <Context.Provider value={props.context}>{props.children}</Context.Provider>;
};
