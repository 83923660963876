import { Image } from "@chakra-ui/image";
import { Heading, Stack } from "@chakra-ui/layout";
import { ts } from "@libry-content/localization";
import { getSiteUrl } from "@libry-content/common";
import NextLink from "next/link";
import React from "react";
import { imageUrlBuilder } from "../../utils/sanity/client";
import { ResolvedSite } from "../site/sanityQuery";

interface Props {
  site: ResolvedSite;
}

function SitePreview({ site }: Props) {
  return (
    <NextLink href={getSiteUrl(site)} passHref>
      <Stack
        as="a"
        direction="row"
        alignItems="center"
        gridGap="1rem"
        _hover={{ textDecoration: "underline", background: "rgba(0,0,0,.05)" }}
        borderRadius=".5rem"
        padding=".5rem 1.5rem"
      >
        <Image
          alt=""
          height="2.5rem"
          src={
            imageUrlBuilder((site.logo?.asset && site.logo) || site.coatOfArms)
              ?.height(75)
              .format("webp")
              .url() ?? ""
          }
        />
        <Heading as="h3" size="sm">
          {ts(site.name)}
        </Heading>
      </Stack>
    </NextLink>
  );
}

export default SitePreview;
